@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800&family=Open+Sans:wght@300;400;600;700;800&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,800&family=Roboto:wght@300;400;500;700;900&display=swap');

@import 'reset.css';

/* autocomplete styles */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/*general styles */
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #F7F7F7;
  font-family: 'Open Sans', sans-serif;
  overflow-x:hidden;
}

.login-form-header {
  position: relative;
  width: 80%;
  max-width: 400px;
  margin: 8px auto;
}


.login-form .ant-input{
  border: 1px solid #696D6E !important;
  border-radius: 10px;
  padding: 1em !important;
  box-sizing: border-box;
  margin: 0 !important;
}

.login-form .ant-form-item-control{
  border:none !important;
}

.login-form .ant-input-affix-wrapper{
  border: none !important;

}

.ant-form-item{
  margin-bottom:0 !important;
}

.ant-input-affix-wrapper{
  padding: 0 !important;
}

.login-form .ant-form-item-label {
  z-index: 10;
  text-align: center;
  box-sizing: border-box;
  padding: 0 0.4em;
}

.login-form .ant-form-item-label label{
  height: auto !important;
}

.login-form .ant-form-item{
  position: relative;
}

.login-form .ant-picker{
  width: 100%;
  border: 1px solid #696D6E !important;
  border-radius: 10px;  
  box-sizing: border-box;
}

.login-form .ant-picker-input{
  margin-bottom: 0;
  height: 37px;
}

.login-form .ant-input-password .ant-input-suffix {
  position: absolute;
  top: 1.25em;
  right: 1.25em;
}

.ant-input:focus{
  -webkit-box-shadow: none !important; 
  box-shadow: none !important;
}

.registerRow{
  display: flex;
  justify-content: space-between; 
  flex-flow: row wrap;
  padding: 0 0 25px;
}

.ant-picker-input > input{
  margin-bottom: 0 !important;
  border: none !important;
}

.header-text {
  position: relative;
  top: auto;
  right: auto;
  margin: 0.5em 0;
  text-align: center;
}



.header-text h1{
  font-family: 'Open Sans',sans-serif;
  font-size: 36px;
  font-weight: 800;
  color: #2e748c;
  margin-bottom: 0;
}

.header-text p{
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #92929D;
}

.mainHeader{
  width: 100%;
  height: 88px;
  display:flex;
  align-items: center;
  background: white !important;
  position: fixed !important;
  top: 0%;
  z-index: 99;
}

.login-logo{
  text-align: center;
}

.main-logo {
  width: 169px;
  height: 57px;
  box-sizing: border-box;
  margin-left: 0;
}

.main-logo img{
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  align-content: center;
  flex-direction: row !important;
}

.no-count-paraf{
  margin: 1em 0;
  text-align: center;
}

.ant-form.login-form {
  width: 90%;
  max-width: 480px;
  margin: 0 auto 1.5em;
  background: white;
  padding: 31px 23px;
  border-radius: 20px;
  box-sizing: border-box;
}

.ant-form-item-control-input input {
  width: 100%;
  height: 45px;
  padding: 0 1em;
  box-sizing: border-box;
  border: 1px solid #2E748C;
  border-radius: 10px;
  outline: none;
}

.login-form-button {
  cursor: pointer !important;
  background: #2E748C !important;
  margin: 35px 0  0 !important;
  color: white !important;
  border: none !important;
  width: 100% !important;
  height: 45px !important;
  border-radius: 10px !important;
  text-align: center !important;
  outline: none !important;
}


.login-form-button span{
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
}


.login-form-button button.ant-btn.ant-btn-primary{
  color: white !important; 
  position: static !important;
  top:auto !important;
  width: 100%;
  transform: translateY(0);
  background: transparent;
  height: auto;
  border: none;
}

.password-actions {
  margin: 25px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #2E748C;
}

p.eyeName{
  margin-bottom: 0;
} 

.eye.izquierdo svg:nth-child(2) path {
  stroke: #2F778B;
}

.eye.derecho svg:nth-child(3) path {
  stroke: #2F778B;
}

.password-actions input.ant-checkbox-input {
    width: 20px;
    height: 16px;
    margin-bottom: 0;
    background: #2E748C;
}


div[role="alert"]{
  margin: 0 0 1em;
}

.forgotLink{
  font-size: 12px;
  font-family: 'Open Sans',sans-serif;
  color: #50B5FF;
}

.newAccountBtn{
  color: #2E748C;
  text-decoration: none;
  display: block;
  text-align: center;
}
 
.create-input-holder .ant-form-item-control-input-content{
  display:flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.create-input-holder .ant-checkbox{
  height:auto !important;
  margin-right: 0 !important;
}

.create-input.long{
  width: 100% !important
}

.social-buttons{
  margin: 0 0 25px 0;
}

.social-btn {
  background: transparent;
  border: 2px solid #F1F1F5;
  border-radius: 10px;
  width: 45%;
  height: 45px;
  outline: none;
}

.social-buttons .ant-col.ant-form-item-control{
  width: 100%;
}

.social-buttons .ant-form-item-control-input-content{
  width: 100%;
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.lines{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 25px 0;
}

.lineItem{
  width: 45%;
  height: 1px;
  background: #F1F1F5;
  display: block;
}

.ant-input-password{
  position:relative;
}


.ant-input-password .ant-input-suffix{
  position: absolute;
  top: 0.15em;
  right: 0.8em;
}

.ant-checkbox-input{
  width: 30px;
  height: 50px;
}


.create-input-holder 
.ant-checkbox-wrapper{
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.create-input-holder
.ant-checkbox{
  width: 20px;
  height: 50px;
  margin-right: 15px;
}

.admin-grid{
  width: 100%;
  max-width: 1366px;
  margin:8em auto;
  display:grid;
  min-height: 100vh;
  grid-template-columns: 1fr;
  grid-gap: 0.5em;
  align-items: flex-start;
}

.profile-holder {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 0;
  min-width: 0;
}

.sidebar{
  background: white;
  height: 100%;
  padding: 1em;
  box-sizing: border-box;
  display: none;
}


.sidebar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25em;
}

.sidebar-nav ul li {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}

.linkIcon {
  width: 28px;
}

.sidebar-nav .active{

}

.logo{
  width: 120px
}

.logo img{
  width: 100%;
  height: auto;
}

.sidebar-title {
  transform: translateX(-30px);
  font-size: 17px;
  font-weight: bold;
  font-family:'Open Sans',sans-serif;
  color: #2A4857;
}

.sidebar ul li a {
  text-decoration: none;
  color: #171725;
  font-family: 'Open Sans',sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.sidebar  a.active {
  color: #2E748C;
}

.sidebar  a.active svg path{
  stroke: #2E748C;
}

.sidebar-title span{
  display: block;
  font-weight: 400;
  font-size: 17px;
  font-family:'Open Sans',sans-serif;
  color: #2A4857;
}

.linkIcon {
  width: 20px;
  margin: 0 0.5em 0 0;
  display: inline-block;
}

.linkIcon svg{
  width: 100%;
}

.banner{
  width: 97%;
  margin: 1em auto;
  max-width: 1055px;
}

.visual-control-item {
  background: #EDEDED;
}

.visual-control-figure{
  margin: 0;
}

.visual-control-text {
  padding: 0.5em;
  text-align: center;
  min-height: 170px;
}

.visual-control-text h3{
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: #2F778B;
}

.visual-control-text p {
  font-size: 12px;
  font-family: 'Roboto',sans-serif;
  line-height: 1.5em;
  margin: 0.5em 0;
}

.visual-control-button {
  width: 140px;
  height: 28px;
  margin: 1em auto;
  background: #2E748C;
  text-align: center;
  line-height: 28px;
  border: none;
  color: white;
  border-radius: 35px;
  display: block;
  font-size: 9px;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}

.banner figure{
  width: 100%;
}

.banner img{
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.userInfo{
  background: white;
  border-radius: 10px 10px 0 0;
  margin-bottom: 1em;
}

.userInfo-header{
  background: #abd4d9;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 10px 10px 0 0;
  text-align: center;
}

.send-password-title{
  font-size: 19px;
  font-weight: bold;
  color: rgb(23, 23, 37);
  font-family:'Open Sans',sans-serif;
  text-align: center;
}

.send-password-paraf{
  margin: 1em 0px;
  line-height: 1.25em;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  width: 80%;
  max-width: 350px;
  margin: 0 auto 30px;
  color: #92929D;
  font-weight: normal;
}

#root{
  background: #f0f2f5;
}

.loginFormHolder:before{
  width: 100%;
  height: 100px;
  content: '';
  display: block;
}

.user-photo {
  width: 158px;
  height: 158px;
  text-align: center;
  position: relative;
  margin: 1em auto;
}

.edit-icon {
  position: absolute;
  right: 3em;
  bottom: 1em;
}

.user-photo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin: 0 auto;
}

.userName {
  font-weight: 700;
  font-family: 'Poppins',sans-serif;
  font-size: 14px;
}

.userRole {
  font-size: 12px;
  font-family: 'Open Sans',sans-serif;
  font-weight: normal;
  color: #141414;
}

.userInfo-body {
  display: block;
  padding: 25px;
  font-weight: bold;
  box-sizing: border-box;
}

.userInfo-body label {
  font-family: 'Open Sans', sans-sefif;
  margin: 14px 0 10px;
  display: block;
  font-weight: bold;
  color: #92929D;
  font-size: 10px;
  text-transform: uppercase;
}

.userInfo-body p{
  font-weight: 400;
  font-size: 12px;
  font-family: 'Open Sans',sans-serif;
}

.visual-article {
  background: white;
}

.visual-header {
  background: #CFE4E6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #2F778B;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  padding: 1em;
  box-sizing: border-box;
  line-height: 1.25em;
}

.user-info-left svg {
  margin-right: 0.5em;
}

.eye-table-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
}

.eye-title {
  width: 49px;
  word-break: break-word;
  display: inline-block;
  margin-left: 0.75em;
}

.eye-table-body {
  padding: 0 1em;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
}

.eye-table-column {
  width: 50%;
  box-sizing: border-box;
  padding: 1em;
}

.eye-table-column:first-child {
  border-right: 1px solid #B9B9B9;
}

.eventDay {
  width: 24px;
  position: relative;
}

.eventDay.completada svg path {
  stroke: #2F778B ;
}

.eventDay.completada  .dayNumber{
  color: #2F778B;
}

.dayNumber {
  position: absolute;
  top: 10px;
  left: 7px;
  text-align: center;
  width: 12px;
  font-size: 10px !important;
}

.eventName {
  width: calc(100% - 24px);
  margin-left:0.5em;
}

.eye-table-footer {
  padding: 2em;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  border-top: 1px solid #B9B9B9;
  margin: 1em 0 0;
}

.eye-table-side {
  width: 50%;
  font-size: 10px;
  font-weight: bold;
  color: #2F778B;
  font-family: 'Open Sans',sans-serif;
}

.eye-table-column label, .eye-table-column p {
  color: #92929D;
  display: block;
  font-weight: bold;
  font-size: 12px;
  margin: 0 0 25px;
}

.eye-table-column p{
  font-size: 12px;
  color:#171725;
  font-weight: 600;
}

.calendarGrid{
  display: grid;
  grid-template-columns: 1fr 185px;
  background: white;
  grid-gap: 1em;display: grid;
  grid-template-columns: 1fr;
  background: white;
  grid-column-gap: 49px;
  padding: 25px;
  box-sizing: border-box;
}
 
.calendarGrid .fc-view-harness{
  height: 380px !important;
  overflow: visible !important;
}

.calendar-info header {
  background: #EFEFEF;
  width: 100%;
  color: #7B7B7E;
  font-weight: 600;
  padding: 1em 0;
  text-align: center;
  margin: 0 0 25px;
  border-radius: 15px;
}

.calendarList {
  padding: 0 1em;
}

.fieldHeader{
  background: #CFE4E6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #2F778B;
  font-weight: bold;
  border-radius: 8px;
  padding: 1em;
  box-sizing: border-box;
  line-height: 1.25em;
  margin: 0 0 25px;
}

.calendarList li {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
}

.completada .fc-event-title.fc-sticky{
  background: #2F778B;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  
  color: transparent;
}

.fc .fc-daygrid-event-harness-abs{
  visibility: visible !important;
}

.programada .fc-event-title.fc-sticky{
  background: #A0A0A0;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  color: transparent;
}

.eventDay.completada svg g{
  stroke: #54D598 !important;
}

.visual-control-slider {
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
}

.eventName.programada p, .eventName.completada span {
  color: #A0A0A0;
}

.eventDay.programada svg g{
  stroke: #A0A0A0;
}

.treatmentTable-holder{
  width: 100%;
  background:white;
  overflow:hidden;
  min-width: 0;
}

.profile-main{
  min-width: 0;
}

.treatmentTable-holder 
.tabulator-row 
.tabulator-cell:nth-child(3){
  text-align: center;
}

.treatmentTable-holder
.tabulator 
.tabulator-row 
.tabulator-cell{
  border-right: none;
}

.treatmentTable-holder .tabulator {
  border: none;
  background: transparent;
}

.treatmentTable-holder .tabulator .tabulator-header .tabulator-col{
  border-right: none;
}

.treatmentTable-holder .tabulator-header {
  border-radius: 10px !important;
  padding: 0.5em 0 !important;
  box-sizing: border-box !important;
}

.treatmentTable-holder .tabulator-col-title {
  color: #7B7B7E;
  font-size: 12px;
  font-family: 'Open Sans',sans-serif;
  font-weight: 700;
}

.treatmentTable-container {
  width: 90%;
  margin: 1em auto;
  overflow: hidden;
}

.treatmentTable-holder .tabulator-row.tabulator-row-even {
  background-color: white;
}

.treatmentTable-holder .tabulator-row.tabulator-selectable:hover{
  background-color: white;
}

.treatmentTable-holder .tabulator .tabulator-header{
  border-bottom: none;
}

.treatmentTable-holder 
.tabulator 
.tabulator-header 
.tabulator-col:nth-child(3) {
  text-align: center;
}

.treatmentTable-holder 
.tabulator-header{
  border-radius: 10px;
  padding: 0.5em;
}

.eye-table-footer a {
  color: #2F778B;
  text-decoration: none;
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'Open Sans',sans-serif;
  font-weight: normal;
}

.visual-control-figure img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sidebar button.ant-btn.ant-btn-primary {
  background: white;
  border: 2px solid #2F778B;
  width: 172px;
  color: #2F778B;
  font-weight: bold;
  outline: none;
  border-radius: 15px;
  cursor: pointer;
  margin: 3em 0 0;
}

.datesContent{
  height: calc(100% - 88px);
}

.datesGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  align-items: flex-start;
  min-width: 0;
  min-height: 0;
}

.datesGrid-calendar {
  margin: 1em 0;
  padding: 25px;
  background: white;
  height: 520px;
}

.dates-table{
  min-width: 0;
}

.datesGrid-calendar .fc-direction-ltr.fc-theme-standard{
  height: 100%;
}

.calendarTable {
  background: white;
  padding: 25px 0;
  min-width: 0;
  height: 400px;
}



.calendarTable .tabulator-headers{
  border-radius: 0;
}

.calendarTable .tabulator-headers {
  background: #EFEFEF;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 0;
}


.calendarTable .tabulator-row-even{
  background: #EFEFEF !important;

}


.calendarTable .tabulator-cell{
  padding: 0.75em 0 !important;
}

.tratments-grid {
  width: 100%;
  max-width: 1366px;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
}

.treatments-holder {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  min-height: 0;
  min-width: 0;
}

.tratamiento-holder a{
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  text-decoration: none;
  border-radius: 50%;
  background: #ABD4D9;
  color: #6E6E6E;
  display: inline-block;
  margin: 0 0 0 5px;
  font-size: 17px;
  font-family: 'Open Sans',sans-serif;
  font-weight: bolder;
}

.dateStatus-holder{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5em;
  box-sizing: border-box;
}

.editDate{
  background: #b9ccd4;
  color: white;
  padding: 0.5em 0;
  border: none;
  outline: none;
  display: inline-block;
  margin: 0 0 0 0.5em;
  padding: 0.5em;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.newDate{
  background-color: rgb(78, 118, 136);
  color: white;
  border: none;
  outline: none;
  float: right;
  margin: 1em 0.75em 0px 0px;
  padding: 0.5em;
  box-sizing: border-box;
  width: 172px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Open Sans',sans-serif;
  cursor: pointer;
}

.date-info {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
}

.modal-dates-holder {
  width: 90%;
  margin: 0 auto;
  background: white;
  padding: 2em;
  box-sizing: border-box;
}

.modalDatesTitle {
  font-size: 30px;
  font-family: 'Open Sans';
  color: #2F778B;
  font-weight: bold;
  text-align: center;
  margin: 0;
}

.date-info h4 {
  font-size: 17px;
  font-weight: bold;
  font-family: 'Open Sans',sans-serif;
  text-align: center;
  color: #2E748C;
}

.item-paraf.long {
  width: 100%;
  display: block;
}

.item-paraf {
  width: 44%;
  color: #2A4857;
  font-size: 12px;
  font-weight: normal;
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  width: auto;
  margin: 1em;
}

.treatments-holder .treatment-footer {
  display: none;
}

.modalEdit .ant-modal-footer{
  display:none;
}

.treatments-holder .treatmentTable-holder{
  height: auto;
}

.treat-table-container {
  width: 90%;
}

.user-info-holder {
  display: grid;
  grid-template-columns:1fr;
  padding: 1em 0;
  align-items: flex-start;
  grid-gap: 1em;
  border-radius: 10px;
}

.user-info-holder ul{
  padding: 1em
}

.user-info-left .user-photo img{
  width: 158px;
  height: 158px;
}

.user-info-left ul li {
  margin: 0 0 25px;
  color: #424445;
  font-family: 'Open Size', sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.calendarList li p {
  font-size: 11px;
  font-family: 'Open Sans', sans-serif;
  color: #44444F;
  margin-bottom: 0;
}

.calendarList li span{
  font-size: 10px;
  font-family: 'Open Sans', sans-serif;
  color: #919193;
}

.calendarTable 
.tabulator .tabulator-header 
.tabulator-col {
  background: transparent;
}

.dateStatus {
  width: 100px;
  font-size: 12px;
  font-family: 'Roboto',sans-serif;
  text-align: center;
  border-radius: 10px;
  padding: 0.5em;
  box-sizing: content-box;
  margin: 0;
}

.dateStatus.programada {
  background: rgb(151 151 151 / 20%);
}

.dateStatus.completada {
  color: white;
  background: #2F778B;
  line-height: 13px;
}

.calendarTable .tabulator{
  border: none;
}

.calendarTable .tabulator-row .tabulator-cell{
  border-right: none;
}

.calendarTable .tabulator 
.tabulator-header .tabulator-col{
  border-right: none;
}

.calendarTable .tabulator-cell{
  text-align: center;
}

h2.fc-toolbar-title {
  font-family: 'Open Sans',sans-serif;
  font-size: 17px !important;
  color: #171725;
  font-weight: bold;
  display: block;
  text-transform: capitalize;
}

.fc-button{
  background: transparent !important;
  color: #3DD598 !important;
  border: none !important;
}

.pencilButton {
  position: absolute;
    top: 0.25em;
    z-index: 3;
    right: 0;
    height: 44px;
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none;
}

.updateBtn{
  width: 180px;
  margin: 0 auto;
  display: block;
  background: #2E748C;
  color: white;
  border:none;
  cursor: pointer;
  padding: 0.5em;
  box-sizing: border-box;
}

.fc-button:focus{
  box-shadow: none !important;
}

h2.fc-toolbar-title:before {
  content:'<';
  font-size: 17px !important;
  margin-right: 0.5em;
  color: #ADB0B1;
  display: inline-block;
}

h2.fc-toolbar-title:after {
  content:'>';
  font-size: 17px;
  margin-left: 0.5em;
  color: #ADB0B1;
  display: inline-block;
}

.calendarTableHolder{
  width: 95% ;
  margin: 0 auto !important;
}

.schedule-item {
  width: 90%;
  max-width: 347px;
  margin: 0 auto;
  position: revert;
}

.date-finish-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}

.cancel-date-button {
  width: 147px;
  border: 1px solid #2F778B;
  margin: 0 0.5em 0 0;
  height: 47px;
  background: transparent;
  color: #2F778B;
  font-weight: bold;
  font-size: 13px;
  outline: none;
  cursor: pointer;
}

.change-date-button{
  width: 147px;
  border: none;
  margin: 0 0.5em 0 0;
  height: 47px;
  background: transparent;
  background: #2F778B;
  color: white;
  font-weight: bold;
  font-size: 13px;
  outline: none;
  cursor: pointer;
}

.schedule-select {
  width: 100%;
  margin: 0 0 15px;
  height: 47px;
  padding: 0 0.5em;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 13px;
  border-radius: 10px;
  border: none;
  box-shadow: 3px 6px 12px 0px rgba(0,0,0,0.76);
  -webkit-box-shadow: 3px 6px 12px 0px rgb(0 0 0 / 76%);
  -moz-box-shadow: 3px 6px 12px 0px rgba(0,0,0,0.76);
  outline: none;
}

/* estiolos de consultas */
  
 .calendarGrid 
 .fc-daygrid-event{
   width: 15px;
   height: 15px;
   border-radius: 50%;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-7.5px, -7.5px);
 }

 .calendarGrid 
 .fc-h-event 
 .fc-event-title{
   display: none;
 }

 .eye.izquierdo svg {
  stroke: red;
 }

 .eye.derecho svg {
  stroke: red;
 }
 
/* estilos de marcas de eventos de calendario */

a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-past.completada {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  border: none;
  background: none;
  left: 50%;
  transform: translate(-12px,-5px);
}



/* pantalla de datos */

.formField.personalData-one {
  margin: 1em 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
}

.formField.personalData-one label {
  margin:0.5em 0;
  display: block;
}

.personalData-two {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
}

.personalData-two label{
  display: block;
  margin: 0.5em 0;
}

.personalData-three {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
}

.personalData-three label{
  display: block;
  margin: 0.5em 0;
}

.formField.contactData {
  margin:1em 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
}

.formField.contactData label{
  display:block;
  margin: 0.5em 0;
}

.formField.atentionData-one {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
}

.formField{
  margin: 0 0 25px;
}

.formField.atentionData-two {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
}

.patAgents {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
}

.patAgents .inputHolder, .lifeStyle .inputHolder, .alergies .inputHolder,
.personalPat .inputHolder{  
  display: flex;
  justify-content: space-between;
}

.formField.personalPat {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr;
}

.formField.lifeStyle {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5em;
  margin: 1em 0;
}

.personalPat .inputHolder .ant-form-item-label{
  width: 137px;
}

.formField.atentionData-one label, .formField.atentionData-two label{
  display: block;
  margin: 0.5em 0;
}

.user-info-left {
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
}

.user-info-right {
  background: white;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 10px;
}

.user-info-right label {
  font-size: 13px;
  font-weight: 700;
  font-family: 'MAven Pro',sans-serif;
}

.personalData-one .ant-form-item-label,
.personalData-two .ant-form-item-label,
.personalData-three .ant-form-item-label{
  display: block !important;
  width: 100%;
  text-align: left;
}

.fc-today-button{
  display: none !important;
}

/* Mobile menu */

.ant-page-header-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}



.pushMenu {
  width: 100%;
  position: fixed;
  top: 88px;
  width: 100%;
  right: 0;
  height: calc(100% - 88px);
  background: white;
  padding: 1.5em;
  box-sizing: border-box;
  z-index: 12;
  transform: translatex(100%);
  transition: all 0.3s ease-in-out;
}


.pushMenu.open {
  transform: translateX(0);
}

.burgerButton{
  background:transparent;
  border: none;
  margin-right: 0;
  outline: none;
  z-index: 20;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: #2F778B;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.mobileMenu-nav ul li {
  margin-bottom: 2em;
}

.mobile-sesion-close{
  background: #2F778B;
  color: white;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  padding: 0.5em;
  border: none;
  border-radius: 10px;
}

.mobileMenu-nav ul li a{
  color: #2F778B;
  text-decoration: none;
  font-size: 1.25em;
}

.treatment-footer{
  margin: 1em 0;
  text-align: center;
}

.treatment-footer a{
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  color: #2F778B;
}

.status{
  font-size: 12px;
  padding: 0.5em 0;
  font-family: 'Roboto',sans-serif;
  border-radius: 10px;
}

.status.realizado{
  background: rgba(61,213,152,0.10);
  color: #3DD598;
}

.status.finalizado{
  background: rgba(152,152,152,0.20);
}

/* graduaciones */

.user-info-holder input{
  border: none;
}


.graduations {
  background: #fff;
  padding: 1em;
  box-sizing: border-box;
}

.graduationsItem {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 90%;
  margin: 0 auto 1em;
}

.userIdent img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 0.5em;
}

.userIdentName{
  font-family:'Open Sans',sans-serif;
  font-size: 14px;
  font-weight: 800;
}

.userIdent {
  margin-right: 45px;
  display: none;
}

.tabulator-row .tabulator-cell{
  font-family: 'Roboto',sans-serif;
  font-size: 12px;
}

.tabulator-row .tabulator-cell:nth-child(1){
  font-family: 'Poppins',sans-serif;
  font-size: 12px;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-left {
  border-right: none;
}

/* citas */

.ant-modal.modalEdit 
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #F4F4F4;
}


.fc-theme-standard td, .fc-theme-standard th{
  border: none !important;
}

.fc-theme-standard .fc-scrollgrid{
  border: none !important;
}

/* Responsivos */

@media screen and (min-width: 768px){
  .login-form{
    width: 480px;
  }

  .userIdent {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  
  
  .profile-holder {
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-column-gap: 26px;
    padding: 0.75em;
  }

 

  .sidebar button.ant-btn.ant-btn-primary {
    background: white;
    border: 2px solid #2F778B;
    padding: 1em;
    height: auto;
    box-sizing: border-box;
    width: 172px;
    color: #2F778B;
    font-weight: bold;
    outline: none;
    border-radius: 15px;
    cursor: pointer;
  }

  .formField.personalData-one {
    grid-template-columns: repeat(2,300px);
  }

  .personalData-two {
    grid-template-columns: repeat(2,1fr);
  }

  .personalData-three {
    grid-template-columns: 1fr 194px 94px;
  }

  .formField.contactData {
    display: grid;
    grid-template-columns: 212px 1fr;
    grid-gap: 1em;
  }

  .formField.atentionData-one {
    display: grid;
    grid-template-columns: 303px 1fr;
    grid-gap: 1em;
  }

  .formField.atentionData-two {
    grid-template-columns: 348px 1fr;
  }
  
  .patAgents {
    grid-template-columns: repeat(2,45%);
  }

  .formField.lifeStyle {
    grid-template-columns: repeat(2,1fr );
  }

  .alergies .inputHolder {
    width: 45%;
  }

  .formField.personalPat {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(2,1fr);
  }

  .user-photo{
    order: 2;
  }

  
  .login-form .ant-form-item-label {
    position: absolute;
    top: -0.6em;
    left: 1.75em;
    background: white;
    z-index: 10;
    text-align: center;
    box-sizing: border-box;
    padding: 0 0.4em;
  }
  

  .graduationsItem article {
    width: 45%;
  }
  
  .container{
    align-items: center;
    align-content: center;
  }

}

/* Test glaucoma */

html,body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.glaucoma-header {
  width: 100%;
  max-width: 1049px;
  height: 67px;
  background: #F4F4F4;
  text-align: center;
  color: #838383;
  font-weight: bold;
  line-height: 67px;
  text-transform: uppercase;
}

.glaucomaSection {
  width: 100%;
  max-width: 1049px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
.glaucoma-open-question h1,
.glaucoma-question h1 {
  font-size: 24px;
  font-family: 'Open Sans',sans-serif;
  font-weight: bold;
  color: #2E748C;
  text-align: center;
  padding: 0 1em;
}

.glaucoma-open-question h1,
.glaucoma-question h1 {
  padding: 0 0.25em !important;
}

.glaucoma-buttons{
  text-align: center;
}

.glaucoma-buttons button {
  width: 171px;
  height: 147px;
  background: #ABD4D9;
  font-size: 24px;
  font-weight: bold;
  font-size: 24px;
  font-family: 'Open Sans',sans-serif;
  border: none;
}

.glaucoma-buttons button:first-child{
  margin-right: 10px;
}

.glaucoma-options {
  text-align: center;
}

.glaucoma-option {
  width: 174px;
  height: 47px;
  background: #ABD4D9;
  margin: 0 0.5em;
  display: flex;
  align-items: center;
  padding: 0.5em;
  margin-bottom: 15px;
  outline: none;
}

.glaucoma-option .letter {
  width: 24px;
  height: 24px;
  line-height: 24px;
  color: #4A4343;
  font-weight: bold;
  background: #fff;
  border: 1px solid #2E748C;
  margin-right: 15px;
}

.glaucoma-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.glaucoma-optionsList {
  width: 174px;
  display: flex;
  width: 174px;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.glaucoma-optionsList span{
  width: 24px;
  height: 24px;
  line-height: 24px;
  color: #4A4343;
  font-weight: bold;
  background: #fff;
  border: 1px solid #2E748C;
  margin-right: 15px;
  text-align: center;
  display: inline-block;
}

.glaucoma-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 285px 1fr;
  align-items: center;
  grid-column-gap: 90px;
}

.glaucoma-grid-right{
  font-size: 20px;
  font-weight: normal;
  font-family: 'Open Sans',sans-serif;
}

.glaucoma-grid-right h1{
  color: #2E748C;
  font-size: 24px;
  font-family: 'Open Sans',sans-serif;
  font-weight: bold;
}

.boldText{
  font-weight: bold;
}

.makeDateBtn {
  width: 157px;
  height: 47px;
  background: #50CCD3;
  border: none;
  font-size: 13px;
  color: white;
  font-weight: bold;
  margin-left: 15px;
  border-radius: 10px;
}

.finishButtonBlue {
  width: 227px;
  font-size: 13px;
  height: 47px;
  border: none;
  color: white;
  background: #2E748C;
  border-radius: 10px;
  margin-right: 38px;
  font-weight: bold;
}

.finishButtonGray {
  width: 102px;
  height: 48px;
  font-size: 13px;
  font-weight: bold;
  border: none;
  color: white;
  background: #929495;
  border-radius: 10px;
}

.formatterCell{
  justify-content: flex-end;
  display: flex;
  align-items: center;
  margin: -3px 0 0;
}



/* Pantalla de test de retina */

.retinaGrid {
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 250px 1fr;
  height: 100%;
}

.retina-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.retinaSection {
  width: 100%;
  max-width: 1049px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: white;
  flex-flow: row wrap;
  position: relative;
}

.retinaHeader {
  width: 100%;
  background: #F4F4F4;
  height: 67px;
  line-height: 67px;
  text-align: center;
  font-size: 21px;
  font-family: 'Open Sans',sans-serif;
  font-weight: bolder;
  color: #838383;
}

.retina-inner-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 535px 1fr;
  align-items: center;
  padding: 2em;
  grid-gap: 40px;
}

.retina-next-button {
  background: #2E748C;
  width: 263px;
  color: white;
  font-size: 13px;
  border: none;
  height: 47px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
}

.figure-retina {
  width: 140px;
  position: absolute;
  bottom: 10px;
  right: 0px;
}

.figure-retina img{
  width: 100%;
  height: auto;
}

.retina-right p{
  font-size: 18px;
  font-weight: normal;
  font-family: 'Open Sans',sans-serif;
}

.retina-left{
  position: relative;
}

.point {
  width: 17px;
  height: 17px;
  background: black;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-8.5px, -8.5px);
}

.retina-right h3 {
  color: #2E748C;
  font-size: 21px;
  font-weight: bold;
  font-family: 'Open Sans',sans-serif;
}

.retina-button-item {
  background: #ABD4D9;
  border: none;
  margin: 0 0 0 0.25em;
  width: 155px;
  height: 124px;
  font-size: 21px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
}

.retina-inner-grid-two {
  display: grid;
  grid-template-columns: 305px 1fr;
  padding: 1.5em;
  grid-gap: 25px;
}

.send-button-one {
  width: 157px;
  height: 48px;
  background: #50CCD3;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 13px;
  font-weight: bold;
  outline: none;
  margin-left: 25px;
}

.end-section-one {
  margin: 0 0 35px;
}

.end-section-one strong{
  font-size: 20px;
}

.send-button-two {
  background: #2E748C;
  color: white;
  font-size: 13px;
  font-weight: bold;
  width: 223px;
  height: 48px;
  border-radius: 10px;
  margin: 0 2em 0 0;
  border: none;
  outline: none;
}

.send-button-three {
  background: #929495;
  width: 102px;
  height: 48px;
  color: white;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  border: none;
  outline: none;
}

/*Pantalla de mis lentes*/

.glassesGrid {
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 248px 1fr;
}

.glasses-bannerTwo {
  width: 436px;
  margin: 20px auto;
  position: relative;
}

.bannerTwoBtn{
  width: 157px;
  height: 48px;
  color: white;
  font-family: 'Open Sans',sans-serif;
  cursor: pointer;
  background: #50CCD3;
  line-height: 48px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 13px;
  border: none;
  outline: none;
  position: absolute;
  bottom: 90px;
  left: 30px;
}

.info-side {
  font-size: 14px;
  font-family: 'Open Sans',sans-serif;
}

.info-label {
  font-weight: 700;
  margin-right: 20px;
}

.glasses-model {
  width: 410px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.glasses-photo {
  width: 244px;
  height: 150px;
  line-height: 100px;
  padding: 2em;
  box-sizing: border-box;
  background: #ABD4D9;
  margin: 0 20px 0 0;
}

.model-info {
  align-self: center; 
}

.model-info h4 {
  color: #7B7B7E;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans',sans-serif;
}

.model-info p {
  font-size: 14px;
  font-family: 'Roboto',sans-serif;
}

.glasses-row-one {
  width: 400px;
  display: flex;
  justify-content: space-between;
  margin: 0 0 1em;
}

.glassesTable .treatmentTable-holder{
  padding: 1.5em;
  box-sizing: border-box;
}

.glassesInfo-table {
  display: grid;
  grid-template-columns: 410px 1fr;
  column-gap: 25px;
}

.glassesTable {
  width: 100%;
  max-width: 1056px;
  margin: 1em auto;
}

.glasses-info {
  display: flex;
  justify-content: space-between;
  /* align-items: flex-start; */
  align-self: flex-start;
  margin: 1.5em 0 0;
}

.glassesStatus {
  display: flex;
  justify-content: space-between;
}


.glassesStatus span{
  font-size: 12px;
  font-family: 'Roboto',sans-serif;
  margin: 0 0 0 1em;
}

.splide__pagination{
  display: none;
}

.eye.izquierdo svg ,
.eye.derecho svg{
  stroke: #2E748C;
}



/* Responsivos */

@media screen and (min-width: 768px){
  .splide__arrows{
    display: none;
  }
}

@media screen and (min-width: 1200px){
  .admin-grid{
    grid-template-columns: 248px 1fr;
    grid-gap: 26px;
    height: 100%;
    margin: 0 auto;
  }

  .treatments-holder{
    margin: 1em 0 0;
  }

  .mainHeader{
    position: relative !important;
  }
  
  .calendarGrid{
    grid-template-columns: 1fr 185px;
  }

  .sidebar{
    display: block;
  }
  
  .datesGrid {
    grid-template-columns: 416px 1fr;
  }

  .tratments-grid {
    grid-template-columns: 248px 1fr;
  }

  .user-info-holder {
    grid-template-columns: 290px 1fr;
  }
  
  .user-info-holder input{
    background: #F8F8F8;
    border-radius: 0!important;
    outline: none;
    color: #65686A;
    font-weight: bold;
    font-family: 'Maven Pro',sans-serif;
  }

  .data-option svg path {
    stroke: #424445; 
  }
  
  .data-option svg g{
    stroke: #424445; 
  }
  

  .data-option.selected svg path {
    stroke: #2E748C;
  }

  .data-option.selected{
    color: #2E748C;
    font-weight: bold;
  }

  .user-photo{
    order: 0;
  }

  .user-info-left ul{
    width: 100%;
    order: 2;
  }

  .burgerButton{
    display: none;
    margin-right: 0;
  }

  .header-text {
    text-align: center;
    position: absolute;
    top: 2em;
    right: 5em;
    margin: .5em 0;
  }

  .login-logo{
    text-align: left;
  }

}